// Styles
import '../icons/style.css';
import 'toastify-js/src/toastify.css';
import 'tippy.js/dist/tippy.css';
import 'zoomist/css';
import '../css/modules.scss';
import '../css/app.css';

// For Validation
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isJSON from 'validator/lib/isJSON';

// Custom Plugins
import ScrollHeader from './modules-custom/ScrollHeader';
import ScrollSmooth from './modules-custom/ScrollSmooth';
import ScrollToTop from './modules-custom/ScrollToTop';
import SlideShow from './modules-custom/SlideShow';
import ShowMore from './modules-custom/ShowMore';
import { ShowMoreLC } from './modules-custom/ShowMore';
import RangeSelect from './modules-custom/RangeSelect';
import VideoAutoplay from './modules-custom/VideoAutoplay';
import FloatSidebar from './modules-custom/FloatSidebar';
import Tooltip from './modules-custom/Tooltip';
import Burger from './modules-custom/Burger';
import Tabs from './modules-custom/Tabs';
import Accordion from './modules-custom/Accordion';
import RequestQueue from './modules-custom/RequestQueue';

// NPM Plugins
import '@iconfu/svg-inject';
import Cookies from 'js-cookie';
import MicroModal from 'micromodal';
import Toastify from 'toastify-js';

// Some Usefull Functions
import {
  toggleLoader,
  onShowMicroModal,
  onCloseMicroModal,
  refreshFragments,
} from './modules-custom/functions';

// Declarete WP functions
const { translations } = theme;
let IMask = null;

// Example usage:
const queues = {
  watchlist: new RequestQueue(),
  compare: new RequestQueue(),
};

class ScrollIneractive {
  constructor(id) {
    this.button = document.getElementById(id);
    // this.dott = document.getElementById("dott");
    this.dotts = document.querySelectorAll('.LineScrollDott');
    this.lastPosition = 0;
    if (this.button) {
      this.update = this.update.bind(this);
      setTimeout(() => {
        this.init();
      }, 2000);
      window.addEventListener('resize', () => {
        this.destroy();
        this.init();
      });
    }
  }

  init() {
    this.headerHeight = document.getElementById('header').clientHeight;
    this.vieportWithoutHeaderHeightHalf =
      (document.documentElement.clientHeight - this.headerHeight) / 1.5;
    this.buttonOffsetTop = this.getTotalOffsetTop(
      document.getElementById('LineScrollStart'),
    );
    this.dottsOffsetTop = this.dotts?.length
      ? [...this.dotts].map((el) => this.getTotalOffsetTop(el))
      : [];
    this.update();
  }

  destroy() {
    cancelAnimationFrame(this.animationFrameId);
  }

  getOffset(element) {
    const rect = element.getBoundingClientRect();
    const distanceToTop = rect.top + window.scrollY;
    return distanceToTop + this.headerHeight;
  }

  getTotalOffsetTop(element) {
    var totalOffsetTop = 0;

    while (element) {
      totalOffsetTop += element.offsetTop;
      element = element.offsetParent;
    }

    return totalOffsetTop + this.headerHeight;
  }

  update() {
    const currentScrollPosition =
      document.documentElement.scrollTop +
      this.headerHeight +
      this.vieportWithoutHeaderHeightHalf;
    if (this.lastPosition == currentScrollPosition) {
      this.animationFrameId = requestAnimationFrame(this.update);
      return false;
    }

    this.dotts.forEach((el, index) => {
      if (currentScrollPosition > this.dottsOffsetTop[index]) {
        el.parentElement.parentElement.classList.add('active');
      } else {
        el.parentElement.parentElement.classList.remove('active');
      }
    });

    // this.dott.style.setProperty("top", `${currentScrollPosition}px`)

    if (currentScrollPosition > this.buttonOffsetTop) {
      this.button.style.setProperty(
        '--tw-translate-y',
        `${currentScrollPosition - this.buttonOffsetTop}px`,
      );
    }
    this.lastPosition = currentScrollPosition;
    this.animationFrameId = requestAnimationFrame(this.update);
  }
}

function setZoomist() {
  setTimeout(async () => {
    const ZoomistModule = await import('zoomist');
    const Zoomist = ZoomistModule.default;
    new Zoomist('.zoomist-container', {
      slider: true,
      zoomer: true,
      wheelable: false,
      // slider: false,
    });
  }, 2000);
}

document.addEventListener('turnstile-loaded', function () {
  document
    .querySelectorAll('form[data-form-callback] button[disabled]')
    ?.forEach((element) => {
      element.disabled = false;
    });
});

// Встановлюємо ширину скролбара
setScrollBarWidth();

document.addEventListener('compare-add', () => {
  new ScrollHeader('CompareSlider');
});
document.addEventListener('compare-remove', () => {
  new ScrollHeader('CompareSlider');
});

document.addEventListener('DOMContentLoaded', () => {
  // Меню
  window.burger = new Burger();

  document.querySelectorAll('.VideoAutoplay')?.forEach((el) => {
    new VideoAutoplay(el);
  });
  // Замінюємо всі img з класом style-svg на svg, з затримкою в 1500мс
  replaceSVG(1500);

  // Встановлюємо висоту хедеру
  setHeaderHeight();

  new ScrollIneractive('LineScroll');

  // Додаємо класс active коли елемент стає Sticy
  new ScrollHeader('BtnToggleFilter');
  new ScrollHeader('CompareSlider');

  // Слайдшоу
  new SlideShow('.SlideShow', 3000);

  // Модальні вікна
  setPopups();

  // Скидаємо кастомне повідомлення про помилку валідації
  document
    .querySelectorAll('input[name="Email"], input[name="MobilePhone"]')
    ?.forEach((el) => {
      el.addEventListener('input', () => {
        el.setCustomValidity('');
        el.checkValidity();
      });
    });

  // Перемикач FormID та SalesChannel при зміні кнопки
  document.querySelectorAll('.SalesChannel')?.forEach((el) => {
    el.addEventListener('click', () => {
      const form = el.closest('form');
      // remove active from all elements
      form
        .querySelectorAll('.SalesChannel')
        .forEach((el) => el.classList.remove('active'));
      // add active to current element
      el.classList.add('active');

      // set formID to input
      const form_id = form.id;
      const inputFormID = document.getElementById(`${form_id}-FormID`);
      const inputSalesChannel = document.getElementById(
        `${form_id}-SalesChannel`,
      );
      try {
        inputFormID.value = el.dataset.formid;
        inputSalesChannel.value = el.dataset.saleschannel;
      } catch (error) {
        console.error(error);
      }
    });
  });

  // Встановимо залежність пар селектів. Щоб унеможливити вибір діапозонів типу від 2018 до 2014 року
  new RangeSelect('.RangeSelect');

  // Плаваючий сайдбар
  new FloatSidebar(
    document.getElementById('sidebar'),
    document.getElementById('content'),
  );

  // Показати/Сховати контент
  document.querySelectorAll('.ShowMore')?.forEach((el) => {
    new ShowMore(el);
  });
  document.querySelectorAll('.ShowMoreLC')?.forEach((el) => {
    new ShowMoreLC(el);
  });

  // Маска на інпути
  setTimeout(() => {
    downloadMasks().then(setMasks);
  }, 1000);

  // Тултипи
  new Tooltip();

  // Скролл до наступного секції після кліку по кнопці.
  setScrollToNextSection();

  // Встановимо обробники форм
  setFormsHendler();

  // Вкладки
  new Tabs();

  // Плавний скролл до секції
  const header = document.getElementById('header');
  const burgerNavigation = document.getElementById('burgerNavigation');
  if (header && burgerNavigation) {
    new ScrollSmooth({
      headerHeight: document.getElementById('header').clientHeight,
      navigationHeight:
        document.getElementById('burgerNavigation').clientHeight,
    });
  }
  // Кнопка Вверх
  new ScrollToTop({
    targetSelector: '.aioseo-breadcrumbs, main > *',
    scrollToTopButtonId: 'btn-back-to-top-desktop',
  });
  new ScrollToTop({
    targetSelector: '.aioseo-breadcrumbs, main > *',
    scrollToTopButtonId: 'btn-back-to-top-mobile',
  });

  // Open Filter Button
  const btnOpenFilter = document.getElementById('BtnToggleFilter');
  if (btnOpenFilter) {
    btnOpenFilter.addEventListener('click', () => {
      window.showFilterBlock();
    });
  }

  // Set tabs on team page
  window.aboutTeamCityList();

  // TODO Зробити порядок
  // document.querySelectorAll("[data-accordion]").forEach(el => {
  // 	el.querySelectorAll('details').forEach((element, index, array) => {
  // 		element.addEventListener("click", () => {
  // 			array.forEach(el => el.removeAttribute("open"))
  // 		})
  // 	})
  // })
  document.querySelectorAll('details')?.forEach((el) => {
    new Accordion(el);
  });

  // document.querySelectorAll("[data-vector-card]")?.forEach((el) => {
  // 	new AnimatedLineClampBlock(el);
  // });

  // Analitycs. Відстежуємо помилки на інпутах
  setInputErrorListenner();

  // Set Default Modal Data
  window.defaultModaLdata = {};
  document.querySelectorAll('.modal').forEach((modal) => {
    window.defaultModaLdata[`${modal.id}`] = {
      title: document.getElementById(`${modal.id}-title`)?.innerText,
      text: document.getElementById(`${modal.id}-text`)?.innerText,
      btn: document.getElementById(`${modal.id}-btn`)?.innerText,
    };
  });

  if (document.querySelectorAll('.zoomist-container').length > 0) {
    setZoomist();
  }

  document.querySelectorAll('img')?.forEach((el) => {
    el.addEventListener(
      'error',
      () => (el.src = theme.directory + '/src/img/placeholder-auto.jpg'),
    );
  });

  document.querySelectorAll('[href="#modal"]').forEach((el) =>
    el.addEventListener('click', () => {
      window.setOpenModalWithData(el);
    }),
  );

  function __addStyleToButtonCusbo() {
    const cusboWidget = document.querySelector('app-cusbo-widget');
    const cusboRoot = cusboWidget.shadowRoot;
    const button = cusboRoot?.querySelector('app-widget-button');

    if (cusboWidget && cusboRoot && button) {
      const style = document.createElement('style');
      style.innerHTML = `@media (max-width: 640px) {
				.button {
					bottom: 64px !important;
				}
			}`;
      cusboRoot.appendChild(style);
      return true;
    }

    return false;
  }

  function __addStyleToButton() {
    const binotelWidget = document.querySelector('#bingc-phone-button');
    const dialongWidget = document.getElementById('dialong-webchat');
    const dialongRoot = dialongWidget?.firstElementChild.shadowRoot;
    const button = dialongRoot?.querySelector('.dchat-bubble');

    if (binotelWidget && dialongWidget && dialongRoot && button) {
      dialongWidget.classList.add('chat-widget-visible');
      binotelWidget.classList.add('binotel-widget-visible');

      const style = document.createElement('style');
      style.innerHTML = `
				@media (max-width: 640px) {
					.dchat-bubble {
						bottom: calc(64px + env(safe-area-inset-bottom));
					}
					#dchat-popup {
						bottom: calc(139px + env(safe-area-inset-bottom));
					}
				}
				.dchat-bubble {
					right: 24px;
				}
				.dchat-operator-photo {
					flex: none;
				}
			`;
      dialongRoot.appendChild(style);
      return true;
    }

    return false;
  }

  function addStyleToButton() {
    if (theme.is_kz) {
      return __addStyleToButtonCusbo();
    } else {
      return __addStyleToButton();
    }
  }

  function tryAddingStyle() {
    const success = addStyleToButton();
    if (success) {
      observer.disconnect(); // Отключаем observer, если все удалось
    } else {
      setTimeout(tryAddingStyle, 100);
    }
  }

  // Створення нового MutationObserver
  const observer = new MutationObserver((mutationsList) => {
    // Перевірка кожної зміни у DOM
    for (let mutation of mutationsList) {
      // Перевірка, чи є додавання нових елементів у DOM
      if (mutation.type === 'childList') {
        // Перевірка наявності обидвох елементів після додавання
        const binotelWidget = document.getElementById('bingc-phone-button');
        const dialongWidget = document.getElementById('dialong-webchat');
        const cusboWidget = document.querySelector('app-cusbo-widget');

        if ((binotelWidget && dialongWidget) || cusboWidget) {
          // Якщо обидва елементи знайдені, викликати функцію
          tryAddingStyle();
          break;
        }
      }
    }
  });

  // Налаштування MutationObserver для спостереження за змінами у DOM
  observer.observe(document.body, { childList: true, subtree: true });
  // addStyleToButton(); // Первоначальная попытка добавить стили, если виджет уже на странице

  // Cookie Pop Up
  // setPrivacyPolicyHendler();
  // if (Cookies.get("PrivacyPolicyAllowed") != "true") {
  // 	setTimeout(() => {
  // 		showPrivacyPolicyPopPup();
  // 	}, 3000);
  // }

  document.querySelectorAll('.TooglePassword')?.forEach((el) => {
    el.addEventListener('click', () => {
      const input = el.previousElementSibling;
      if (input.type === 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    });
  });
});

// Спрацьовує при оновленні слайдів свайпера
document.addEventListener('swiper-reviews-updated', () => {
  document.querySelectorAll('.ShowMoreLC')?.forEach((el) => {
    if (!el.showMore) {
      new ShowMoreLC(el);
    }
  });
});

document.addEventListener('calculated', function () {
  // Маска на інпути
  if (IMask) {
    setMasks(IMask);
  } else {
    downloadMasks().then(setMasks);
  }
});
document.addEventListener('facetwp-loaded', function () {
  // Маска на інпути
  if (IMask) {
    setMasks(IMask);
  } else {
    downloadMasks().then(setMasks);
  }

  // Встановимо залежність пар селектів. Щоб унеможливити вибір діапозонів типу від 2018 до 2014 року
  new RangeSelect('.RangeSelect');

  // Встановимо обробники форм
  document
    .querySelectorAll('.facetwp-template [data-form-callback]')
    ?.forEach((el) => sendFormData(el));

  // Переклад placeholder в інпутах пошуку
  document.querySelectorAll('.fs-search input')?.forEach((el) => {
    el.placeholder = translations.search;
  });

  // Підрахунок кількості лотів в фільтрі "Майбутні лоти"
  if (document.querySelector('.facetwp-facet-future_lot .facetwp-counter')) {
    // Отримання числа з блоку N1
    const blockN1 = document.querySelector('.facetwp-facet-result_counts');
    const countN1 = parseInt(blockN1.innerText.match(/\d+/)[0]);

    // Отримання числа з блоку N2
    const blockN2 = document.querySelector(
      '.facetwp-facet-future_lot .facetwp-counter',
    );
    document
      .querySelector('.facetwp-facet-future_lot')
      .classList.remove('hidden');

    const countN2 = parseInt(blockN2.innerText.match(/\d+/)[0]);

    // Обчислення різниці
    const difference = countN1 - countN2;

    // Заміна числа в блоку N2 на різницю
    blockN2.innerText = '(' + difference + ')';

    // Додавання класу 'hidden', якщо різниця дорівнює 0
    if (difference === 0) {
      document
        .querySelector('.facetwp-facet-future_lot')
        .classList.add('hidden');
    }
  }

  // Зміна href на посиланнях переходу на іншу мову
  changeFilterUrl();

  // Робимо неактивним селект моделі, якщо не вибрано марку
  document
    .querySelector('.facetwp-facet-model .fs-wrap')
    ?.classList.add('disabled');
  if (typeof FWP.facets['make'] !== 'undefined' && FWP.facets['make'].length) {
    document
      .querySelector('.facetwp-facet-model .fs-wrap')
      ?.classList.remove('disabled');
  }
});

window.addEventListener('resize', () => {
  setScrollBarWidth();
  setHeaderHeight();
});

document.frontendaWriteText = function (element, text) {
  if (theme.page.is_compare) {
    text = text + '?ids=' + Cookies.get('compare');
  }
  navigator.clipboard.writeText(text).then(
    () => {
      element._tippy.show();
    },
    () => {},
  );
};

function isAnyModalOpen() {
  let isAnyModalOpen = false;
  document.querySelectorAll('.modal')?.forEach((el) => {
    if (el.classList.contains('is-open')) {
      isAnyModalOpen = true;
    }
  });
  return isAnyModalOpen;
}

function setPopups() {
  MicroModal.init();

  // TODO Якщо клікнути по TomSelect, то не спрацьовує cancelModal
  if (
    theme.page.is_catalog &&
    !theme.is_kz &&
    !localStorage.getItem('showCloseContent')
  ) {
    // Модальне вікно на сторінці "Catalog"
    const timeOutId = setTimeout(() => {
      if (!isAnyModalOpen()) {
        MicroModal.show('modal-form-await', {
          disableScroll: true,
          onShow: onShowMicroModal,
          onClose: onCloseMicroModal,
          awaitOpenAnimation: true,
          // awaitCloseAnimation: true,
        });
      }
    }, 15000);

    const cancelModal = () => {
      clearTimeout(timeOutId);
      document.removeEventListener('click', cancelModal); // Видаляємо обробник подій після скасування
    };

    document.addEventListener('click', cancelModal);
  }

  if (theme.is_ua && typeof localStorage !== "undefined" && !localStorage.getItem("new-year-popap-shown-1")) {
    setTimeout(() => {
      if (!isAnyModalOpen() && !localStorage.getItem('showCloseContent')) {
        MicroModal.show('modal-form-await-new-year', {
          disableScroll: true,
          onShow: onShowMicroModal,
          onClose: onCloseMicroModal,
          awaitOpenAnimation: true,
        });
        localStorage.setItem('new-year-popap-shown-1', '1');
      }
    }, 20_000);
  }

  if (theme.is_kz && !Cookies.get('dontShowModalFormAwaitKz')) {
    const showModal = () => {
      setTimeout(() => {
        if (!isAnyModalOpen()) {
          MicroModal.show('modal-form-await-kz', {
            disableScroll: true,
            onShow: onShowMicroModal,
            onClose: (modal) => {
              onCloseMicroModal(modal);
              Cookies.set('dontShowModalFormAwaitKz', true, { expires: 1 });
            },
            awaitOpenAnimation: true,
            // awaitCloseAnimation: true,
          });
        }
      }, 30000);
      document.removeEventListener('scroll', showModal); // Видаляємо обробник подій після скасування
    };

    document.addEventListener('scroll', showModal);
  }

  document.querySelectorAll('[data-btn-watchlist]')?.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      MicroModal.show('modal-watchlist', {
        disableScroll: true,
        onShow: onShowMicroModal,
        onClose: onCloseMicroModal,
        awaitOpenAnimation: true,
        // awaitCloseAnimation: true,
      });
    });
  });

  document.querySelectorAll('.js-search-start')?.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      MicroModal.show('modal-search', {
        disableScroll: true,
        onShow: function (modal) {
          onShowMicroModal(modal);
          document.getElementById('search').tomselect.focus();
        },
        onClose: function (modal) {
          onCloseMicroModal(modal);
          // document.querySelector("#search-form-placeholder input").blur()
        },
        awaitOpenAnimation: true,
        disableFocus: true,
        // awaitCloseAnimation: true,
      });
    });
  });
}

function replaceSVG(delay) {
  setTimeout(() => {
    SVGInject(document.querySelectorAll('img.style-svg'));
  }, delay);
}

function setScrollToNextSection() {
  document
    .querySelectorAll('[data-btn-scroll-to-next-section]')
    ?.forEach((el) => {
      el.addEventListener('click', () => {
        const currentSection = el.closest('section');
        const nextSection = currentSection.nextElementSibling;
        nextSection.scrollIntoView({ behavior: 'smooth' });
      });
    });
}

async function downloadMasks() {
  if (!IMask) {
    const IMaskModule = await import('imask');
    IMask = IMaskModule.default;
  }
  return IMask;
}

function setMasks(IMask) {
  const masks = {
    'pl-PL': [
      {
        mask: '000 000 000',
      },
    ],
    'kk-KZ': [
      {
        mask: '+7 000 000 00 00',
      },
      {
        mask: '8 000 000 00 00',
      },
    ],
    'uk-UA': [
      {
        mask: '+{38\\0} 00 00 00 000',
      },
      {
        mask: '+{38} (\\000) 00 00 000',
      },
    ],
  };

  const data = [
    {
      name: 'tel',
      inputs: document.querySelectorAll('input[name="MobilePhone"]'),
      options: {
        mask: masks[theme.i18n.locale],
      },
    },
    {
      name: 'name',
      inputs: document.querySelectorAll('input[name="Contact"]'),
      options: {
        mask: [
          {
            mask: /^[A-Za-zĄĆĘŁŃÓŚŹŻАąćęłńóśźż-ЯҐЄІЇЙК-ЩЬЮЯЁЪЫЭӘҒҚҢӨҰҮҺабвгґдеєжзиіїйк-щьюяёъыэәғқңөұүһ\s]+$/,
          },
        ],
      },
    },
    {
      name: 'engine',
      inputs: document.querySelectorAll('[data-input-engine]'),
      options: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            max: 20000,
            thousandsSeparator: ' ',
          },
        },
      },
    },
    {
      name: 'price',
      inputs: document.querySelectorAll(
        'input[data-input-bid], input[data-input-price]',
      ),
    },
  ];

  for (const item of data) {
    let { options } = item;

    if (!item.inputs) {
      continue;
    }

    item.inputs.forEach((el) => {
      if (el.masked) {
        return false;
      }

      if (item.name === 'price') {
        options = {
          mask: '$num',
          blocks: {
            num: {
              mask: Number,
              max: el.dataset.max ? +el.dataset.max : 10000000,
              min: el.dataset.min ? +el.dataset.min : 0,
              thousandsSeparator: ' ',
            },
          },
        };
      }

      if (theme.is_pl && item.name === 'tel') {
        el.addEventListener('input', () => {
          el.parentElement.classList.remove('valid');
          const iti = window.intlTelInputGlobals.getInstance(el);
          const value = '+' + el.value.replace(/\D/g, '');
          const countryData = iti.getSelectedCountryData();
          const phoneStarts = '+' + countryData.dialCode;
          const mask = intlTelInputUtils
            .getExampleNumber(
              countryData.iso2,
              true,
              intlTelInputUtils.numberType.MOBILE,
            )
            ?.replace(/\d/g, '0');

          const masked = IMask.pipe(value.replace(phoneStarts, ''), {
            mask: mask,
          });

          iti.setNumber(masked);

          if (iti.isValidNumber()) {
            el.parentElement.classList.add('valid');
          }
        });
      } else {
        const mask = IMask(el, options);
        if (item.name === 'tel') {
          mask.on('accept', function () {
            const value = mask.value.replace(/\D/g, '');
            const operator = getOperatorCode(value);

            if (isMobilePhone(value, theme.i18n.locale) && operator) {
              el.parentElement.classList.add('valid');
            } else {
              el.classList.remove('valid');
              el.parentElement.classList.remove('validblock');
            }
          });
          el.addEventListener('input', function () {
            const value = mask.value.replace(/\D/g, '');
            const operator = getOperatorCode(value);
            if (!(isMobilePhone(value, theme.i18n.locale) && operator)) {
              el.parentElement.classList.remove('valid');
            }
          });
        }
        el.mask = mask;
      }
    });
  }

  return true;
}

// button.addEventListener('click', () => {
// 	reset();
// 	if (!input.value.trim()) {
// 	  	showError("Required");
// 	} else if (iti.isValidNumber()) {
// 	  	validMsg.classList.remove("hide");
// 	} else {
// 		const errorCode = iti.getValidationError();
// 		const msg = errorMap[errorCode] || "Invalid number";
// 		showError(msg);
// 	}
// });

function getOperatorCode(value) {
  const operatorCode = value.replace(/\D/g, '').substr(2, 3);
  let operator = false;

  if (theme.i18n.locale === 'uk-UA') {
    const operatorsUA = {
      Vodafone: ['050', '066', '095', '099'],
      Київстар: ['067', '068', '096', '097', '098'],
      Lifecell: ['063', '073', '093'],
      '3Mob': ['091'],
      PEOPLEnet: ['092'],
      Інтертелеком: ['094'],
    };

    for (const [name, codes] of Object.entries(operatorsUA)) {
      if (codes.includes(operatorCode)) {
        return name;
      }
    }
    return false;
  } else if (theme.i18n.locale === 'kk-KZ') {
    const regexPatterns = [
      /^\+7(700|701|702|705|707|708|747|750|751|760|761|762|771|775|776|777|778)\d{7}$/,
      /^8(700|701|702|705|707|708|747|750|751|760|761|762|771|775|776|777|778)\d{7}$/,
    ];

    // Удаляем все символы, кроме цифр и плюса (для международного формата)
    const sanitizedValue = value.replace(/[^+\d]/g, '');
    const normalizedValue = sanitizedValue.startsWith('7')
      ? `+${sanitizedValue}`
      : sanitizedValue;

    // Проверяем соответствие казахстанскому шаблону
    for (const pattern of regexPatterns) {
      if (pattern.test(normalizedValue)) {
        return true;
      }
    }
    return false;
  } else if (theme.i18n.locale === 'pl-PL') {
    return true;
  }
  return operator;
}

/**
 *	Встановлюємо CSS змінні висоти хедеру
 *  --header-height
 *  --header-mobile-bottom-height
 *	@returns {void}
 */
function setHeaderHeight() {
  const headers = [
    document.getElementById('header'),
    document.getElementById('header-mobile-bottom'),
  ];
  headers.forEach((el) => {
    if (el === null) {
      return false;
    }
    const prevWidth = document.documentElement.style.getPropertyValue(
      `--${el.id}-height`,
    );
    const newWidth = `${el.clientHeight}px`;
    if (newWidth !== prevWidth) {
      document.documentElement.style.setProperty(`--${el.id}-height`, newWidth);
    }
  });
  return true;
}

// el.addEventListener("input", () => {
// 	el.setCustomValidity("");
// 	el.checkValidity();
// });

function changeFilterUrl() {
  const currentUrl = window.location.href;
  // знайти всі посилання з атрибутами "lang" та "href"
  const links = document.querySelectorAll('a[lang][href]');

  // оновити значення атрибуту "href" на оновлений URL
  links.forEach((link) => {
    const url = new URL(link.getAttribute('href'), currentUrl);
    url.search = new URLSearchParams(new URL(currentUrl).search);
    link.setAttribute('href', url.toString());
  });
}

function wrapTextInSpan(text) {
  return text.replace(/\{([^}]+)\}/g, '<span>$1</span>');
}

function setFormsHendler() {
  document
    .querySelectorAll('[data-form-callback]')
    .forEach((el) => sendFormData(el));
  document
    .querySelectorAll('[data-form-email-subscribe]')
    .forEach((el) => sendFormData(el));
  // document.querySelectorAll("[data-form-review]").forEach((el) => sendFormData(el));
}

function sendFormData(el) {
  if (el.sendFormData) {
    return;
  }
  el.addEventListener('submit', async (e) => {
    e.preventDefault();
    // Зберігаємо час початку відправлення форми
    const openedAt = Date.now();
    const loadingElements = el.querySelectorAll('[type="submit"]');
    const formData = new FormData(el);
    const formID = el.id;
    const formName = document
      .getElementById(`${formID}-title`)
      ?.innerText.replace(/(<([^>]+)>)/gi, '')
      .trim()
      .toLowerCase();

    if (formData.get('Email') === '') {
      formData.delete('Email');
    }

    if (formData.get('MobilePhone') === '') {
      formData.delete('MobilePhone');
    }

    const hasEmail = formData.has('Email') && formData.get('Email') != '';
    const hasMobilePhone =
      formData.has('MobilePhone') && formData.get('MobilePhone') != '';

    if (theme.is_pl && !(hasEmail || hasMobilePhone)) {
      el.querySelectorAll('[name="Email"], [name="MobilePhone"]').forEach(
        (el) => el.closest('.input').classList.add('pay-attention'),
      );

      document
        .getElementById(`${formID}-pay-attention`)
        ?.classList.remove('hidden');

      sendEventDataToGA4('field_error', {
        field_name: 'Email or MobilePhone',
        error_message: "don't filled",
      });

      return false;
    }

    if (hasEmail) {
      const input = el.querySelector('input[type="email"]');
      if (!isEmail(formData.get('Email'))) {
        input.setCustomValidity(translations.email_invalid);
        input.reportValidity();

        sendEventDataToGA4('field_error', {
          field_name: input.getAttribute('name'),
          error_message: input.validationMessage,
        });
        return false;
      }
    }

    if (hasMobilePhone) {
      const input = el.querySelector('input[name="MobilePhone"]');
      let locale = theme.i18n.locale;

      if (theme.is_pl) {
        try {
          const iti = window.intlTelInputGlobals.getInstance(input);
          const value = iti.getNumber(intlTelInputUtils.numberFormat.E164);
          formData.set('MobilePhone', value);
          const masks = {
            pl: 'pl-PL',
            kz: 'kk-KZ',
            ua: 'uk-UA',
          };
          const countryData = iti.getSelectedCountryData();
          const countryCode = countryData.iso2;
          locale = masks[countryCode] ?? 'any';
        } catch (error) {
          console.error(error);
        }
      }

      const MobilePhone = formData.get('MobilePhone');
      const operator = getOperatorCode(MobilePhone);
      const isItMobilePhone = isMobilePhone(
        MobilePhone.replace(/\D/g, ''),
        locale,
      );
      const notValid = !(isItMobilePhone && operator);

      if (notValid) {
        input.setCustomValidity(translations.phone_invalid);
        input.reportValidity();

        sendEventDataToGA4('field_error', {
          field_name: input.getAttribute('name'),
          error_message: input.validationMessage + ' ' + MobilePhone,
        });

        return false;
      }

      if (theme.is_kz && formData.get('MobilePhone').startsWith('8')) {
        formData.set(
          'MobilePhone',
          '+7' + formData.get('MobilePhone').slice(1),
        );
      }
    }

    formData.append('wpnonce', theme.api.nonce);
    formData.append(
      'cf-turnstile-response',
      document.querySelector('[name="cf-turnstile-response"]')?.value,
    );

    if (!formData.has('SalesChannel')) {
      formData.append('SalesChannel', 'B2C');
    }

    const data = new URLSearchParams(formData);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    loadingElements.forEach((el) => {
      toggleLoader(el);
    });

    let closeModal = false;
    let result = false;
    const response = await fetch(theme.api.ajax_url + '?action=contactForm', {
      method: 'POST',
      headers: headers,
      body: data,
    });

    document.querySelectorAll('.modal')?.forEach((el) => {
      if (el.classList.contains('is-open')) {
        closeModal = true;
      }
    });

    if (closeModal) {
      MicroModal.close();
    }

    try {
      result = await response.json();
    } catch (error) {
      sendEventDataToGA4('lead_unsuccess', {
        form_id: formID,
        form_name: formName,
        error_message: 'js-response.json()',
        fatal: true,
      });
      console.error(error);
      onError(null, translations.error_message);
      loadingElements.forEach((el) => {
        toggleLoader(el);
      });

      return false;
    }

    if (result.success) {
      localStorage.setItem('formData', data);
      localStorage.setItem('showCloseContent', '1');

      document.querySelectorAll('.hidden-content')?.forEach((el) => {
        el.remove();
      });

      if (result.data[0].formType == 'lead') {
        // Розрахунок часу очікування
        const waitingTime = Math.round((Date.now() - openedAt) / 1000); // у секундах
        sendEventDataToGA4('generate_lead', {
          form_id: formID,
          form_name: formName,
          lead_id: result.data[0].leadID,
          sales_channel: formData.get('SalesChannel'),
          link_text: formData.get('popup_btn'),
          phone_number: formData.has('MobilePhone')
            ? formData.get('MobilePhone').replace(/\s/g, '')
            : null,
          waiting_time: waitingTime,
        });

        if (window.fbq) {
          fbq('track', 'Lead');
        }

        if (window.clarity) {
          let clientID = formData.get('MobilePhone');

          if (!clientID) {
            clientID = formData.get('Email');
          }

          window.clarity(
            'identify',
            clientID,
            '',
            theme.page.id,
            formData.get('Contact'),
          );
        }
      } else {
        sendEventDataToGA4('generate_' + result.data[0].formType);
      }

      onSuccess(null, result.data[0].message);
    } else {
      if (result.data.length > 0) {
        onError(null, result.data[0].message);
      } else {
        onError(null, translations.error_message);
      }

      sendEventDataToGA4('lead_unsuccess', {
        form_id: formID,
        form_name: formName,
        error_message: result.data[0].code ?? 'unkwon error',
        fatal: true,
      });
      console.error(result.data[0].code);
    }

    loadingElements.forEach((el) => {
      toggleLoader(el);
    });

    return true;
  });
  el.sendFormData = true;
}

function onSuccess(title, message) {
  if (title) {
    const elementText = document.getElementById('modal-form-thankyou-title');
    elementText.innerHTML = title;
  }
  if (message) {
    const elementText = document.getElementById('modal-form-thankyou-text');
    elementText.innerHTML = message;
  }
  MicroModal.show('modal-form-thankyou');
}

function onError(title, message) {
  if (title) {
    const elementText = document.getElementById('modal-form-error-title');
    elementText.innerHTML = title;
  }
  if (message) {
    const elementText = document.getElementById('modal-form-error-text');
    elementText.innerHTML = message;
  }
  MicroModal.show('modal-form-error');
}

function setInputErrorListenner() {
  document.querySelectorAll('button[type=submit]').forEach((el) => {
    el.addEventListener('click', () => {
      el.closest('form')
        ?.querySelectorAll('input')
        ?.forEach((el) => {
          if (el.checkValidity()) {
            return false;
          }
          sendEventDataToGA4('field_error', {
            field_name: el.getAttribute('name'),
            error_message: el.validationMessage,
          });
        });
    });
  });
}

function sendEventDataToGA4(event, data = {}) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: event,
    ...data,
  });
}

function setScrollBarWidth(event, cssVariableName = '--twcb-scrollbar-width') {
  const prevWidth =
    document.documentElement.style.getPropertyValue(cssVariableName);
  const newWidth = `${window.innerWidth - document.body.clientWidth}px`;
  if (newWidth !== prevWidth) {
    document.documentElement.style.setProperty(cssVariableName, newWidth);
  }
}

async function toggleAction(btn, endpoint) {
  // Встановлюємо стан "завантаження" на кнопці
  btn.disabled = true;
  btn.classList.add('loading'); // Додайте свій стиль для стану завантаження

  const productNode = btn.closest('[data-id]');
  const lotID = productNode?.dataset.id;
  let action = '';
  if (productNode) {
    action = productNode.classList.contains('in-' + endpoint)
      ? 'remove'
      : 'add';
  } else {
    action = 'clear';
  }

  const formData = new FormData();
  formData.append('lot_id', lotID);
  formData.append('action', action);
  formData.append('lang', theme.i18n.language);

  if (theme.page.is_compare) {
    formData.append('is_compare', true);
  }

  // Prepare form data and headers
  const data = JSON.stringify(Object.fromEntries(formData.entries()));
  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-WP-Nonce': theme.api.nonce,
    'Accept-Language': theme.i18n.locale_pll,
  });

  queues[endpoint].addRequest(
    () =>
      fetch(`${theme.api.endpointW8}/${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: data,
      }).then((res) => res.json()),
    (data) => {
      document
        .querySelectorAll(`[data-id='${lotID}']:not(#related_lots)`)
        ?.forEach((el) => {
          el.classList.toggle('in-' + endpoint);
        });

      // productNode

      const counters = document.querySelectorAll(`[data-type="${endpoint}"]`);

      counters.forEach((el) => {
        el.dataset.count = data.count;
        el.parentNode.parentNode.classList.add('tada');
        setTimeout(() => {
          el.parentNode.parentNode.classList.remove('tada');
        }, 1000);
      });

      if (data.fragments && !window.location.toString().includes('?ids=')) {
        refreshFragments(data.fragments);
      }

      data.notices?.forEach((notice) => showToastifyMesage(notice));

      const event = new CustomEvent(`${endpoint}-${data.action}`, {
        detail: {
          lotID: productNode?.dataset.id,
          ...data,
        },
      });
      document.dispatchEvent(event);

      if (data.reload) {
        location.reload();
      }

      // if (productNode.closest(`#${endpoint}`)) {
      // 	productNode.remove();
      // }
    },
    (error) => console.error('Запит помилка:', error),
    btn,
  );
}

function showToastifyMesage(message, type = 'info', duration = 1500) {
  Toastify({
    text: message,
    className: type,
    duration: duration,
    stopOnFocus: true,
    close: true,
    avatar: theme.directory + `/src/img/${type}.svg`,
    offset: {
      y: document.getElementById('header')?.clientHeight ?? 0,
    },
  }).showToast();
}

window.aboutTeamCityList = function () {
  const event = new CustomEvent('afterTabsListChanged');
  let aboutCityList = document.getElementById('about_city_list');
  if (!aboutCityList) {
    return;
  }
  let aboutCityListItems = aboutCityList.querySelectorAll('.city-item');
  let aboutCityListEmployers = document.querySelectorAll('.city-employers');
  aboutCityListItems.forEach((item) => {
    item.addEventListener('click', (e) => {
      aboutCityListItems.forEach((i) => {
        i.classList.remove('active');
      });
      e.target.classList.add('active');
      aboutCityListEmployers.forEach((i) => {
        i.classList.add('hidden');
        if (i.dataset.city === e.target.dataset.city) {
          i.classList.remove('hidden');
        }
      });
      document.dispatchEvent(event);
    });
  });

  if (window.location.search.includes('_type')) {
    const city = window.location.search.split('_type=')[1];
    console.log(city);
    aboutCityListItems.forEach((item) => {
      if (item.dataset.city === city) {
        item.click();
      }
    });
  }
};

document.showToastifyMesage = showToastifyMesage;

// for be possible add prop "onclick" to btn
window.hideFilterBlock = function () {
  const filterBlock = document.getElementById('sidebar');
  const body = document.getElementById('body');
  const footer = document.getElementById('footer');
  body.classList.remove('filter-opened');
  filterBlock.classList.add('-translate-x-full');
  body.classList.remove('overflow-hidden');
  footer.classList.remove('-z-10');
  setTimeout(() => {
    filterBlock.classList.remove('transition-all');
  }, 200);
};

window.showFilterBlock = function () {
  const filterBlock = document.getElementById('sidebar');
  const body = document.getElementById('body');
  const footer = document.getElementById('footer');
  body.classList.add('filter-opened');
  body.classList.add('overflow-hidden');
  footer.classList.add('-z-10');
  filterBlock.classList.add('transition-all');
  filterBlock.classList.remove('-translate-x-full');
};

window.watchlist = async function (btn) {
  await toggleAction(btn, 'watchlist');
};

window.compare = async function (btn) {
  await toggleAction(btn, 'compare');
};

// data-btn-open-modal-form data-{що це?}-{що робить?}-{з ким робить?}-{Модифікатор}
window.setOpenModalWithData = function (el) {
  if (
    document.getElementById('modal-watchlist').classList.contains('is-open')
  ) {
    MicroModal.close('modal-watchlist');
  }

  let modalId;
  let attrs;

  if (el.getAttribute('href') === '#modal') {
    modalId = 'callback';
    attrs = {
      id: 'callback',
      texts: {
        title: translations['attrs.title'],
        text: translations['attrs.text'],
      },
    };
  } else {
    if (isJSON(el.dataset.btnOpenModalForm)) {
      attrs = JSON.parse(el.dataset.btnOpenModalForm);
      modalId = attrs.id;
    } else {
      modalId = el.dataset.btnOpenModalForm;
    }
  }

  document
    .getElementById(modalId)
    ?.querySelector('textarea')
    ?.setAttribute('value', '');

  if (attrs) {
    const { id, texts, values, lot } = attrs;
    if (texts) {
      for (const key in texts) {
        if (Object.hasOwnProperty.call(texts, key)) {
          const element = texts[key];
          if (element === '') {
            continue;
          }
          try {
            if (element === 'hidden') {
              document.getElementById(`modal-form-${id}-${key}`).innerHTML = '';
            } else {
              document.getElementById(`modal-form-${id}-${key}`).innerHTML =
                wrapTextInSpan(element);
            }
          } catch {
            console.error(`Error modal open: modal-form-${id}-${key}`);
          }
        }
      }
    }
    if (values) {
      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const element = values[key];
          try {
            document.getElementById(`modal-form-${id}-${key}`).value = element;
          } catch {
            console.error(`Error modal open: modal-form-${id}-${key}`);
          }
        }
      }
    }
    if (lot) {
      for (const key in lot) {
        if (Object.hasOwnProperty.call(lot, key)) {
          const element = lot[key];
          if (key == 'image') {
            document
              .getElementById(`modal-form-${id}-lot-image`)
              .setAttribute('src', element);
          } else {
            document.getElementById(`modal-form-${id}-lot-${key}`).innerHTML =
              element;
          }
        }
      }
    }
  }

  // Show Modal
  console.log('modal-form-' + modalId);

  MicroModal.show('modal-form-' + modalId, {
    disableScroll: true,
    onShow: onShowMicroModal,
    onClose: onCloseMicroModal,
    awaitOpenAnimation: true,
    // awaitCloseAnimation: true,
  });
};

document.setBiaTvCookie = function () {
  let cookie = Cookies.get('biatv-cookie');
  // Check if the cookie exists and is not empty
  if (cookie) {
    try {
      cookie = JSON.parse(cookie);
      // Check if "utmDataCurrent" property exists in the cookie
      if (cookie && cookie.utmDataCurrent) {
        cookie = cookie.utmDataCurrent;

        // Remove the "beginning_at" property
        delete cookie.beginning_at;

        // Iterate through properties and set values if corresponding elements exist
        for (const key in cookie) {
          if (Object.hasOwnProperty.call(cookie, key)) {
            const element = document.getElementById(key);
            if (element && element.value === '(not_set)') {
              element.value = cookie[key];
            }
          }
        }
      } else {
        console.error("Missing 'utmDataCurrent' property in the cookie.");
      }
    } catch (error) {
      console.error('Error parsing cookie:', error);
    }
  } else {
    console.error("Cookie 'biatv-cookie' not found.");
  }
};

document.addEventListener('DOMContentLoaded', () => {
  document
    .getElementById('offer_form')
    ?.addEventListener('submit', function (e) {
      e.preventDefault();
      const modelSelect = document.getElementById('model');
      const makeSelect = document.getElementById('make');
      const yearFromInput = document.getElementById('YearFrom');
      const yearToInput = document.getElementById('YearUp');

      const from = yearFromInput.value
        ? yearFromInput.value
        : yearFromInput.dataset.minMax;
      const to = yearToInput.value
        ? yearToInput.value
        : yearToInput.dataset.minMax;
      const year = [from, to].join(',');
      const params = new URLSearchParams({
        _year: year,
      });
      const cats = [
        makeSelect.value ? makeSelect.value : '',
        modelSelect.value ? modelSelect.value : '',
      ]
        .filter((el) => el)
        .join('/');

      window.open(this.action + cats + '?' + params.toString());
    });

  // Utility function to reset and disable a TomSelect element
  function resetAndDisableSelect(selectElement) {
    selectElement.innerHTML = '';
    selectElement.tomselect.clear();
    selectElement.tomselect.clearOptions();
    selectElement.setAttribute('disabled', true);
    selectElement.parentElement.classList.add('disabled');
  }

  // Utility function to enable a TomSelect element
  function enableSelect(selectElement) {
    selectElement.setAttribute('disabled', false);
    selectElement.parentElement.classList.remove('disabled');
  }

  // Utility function to populate select options
  function populateSelectOptions(selectElement, options) {
    options.forEach((el) => {
      const option = document.createElement('option');
      option.value = el.value;
      option.innerHTML = el.label;
      selectElement.insertAdjacentElement('beforeend', option);
    });
    selectElement.tomselect.sync();
  }

  // Fetch models and years data based on selected make and model
  async function fetchModelsAndYears(make, model = null) {
    const body = {
      data: {
        facets: {
          make: [make],
          model: model ? [model] : [],
          year: [],
        },
        query_args: {
          post_type: 'lot',
          posts_per_page: -1,
          paged: 1,
        },
      },
    };

    const response = await fetch(theme.api.endpoint + 'facetwp/v1/fetch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${theme.credendionals.FACET_WP_AUTH_TOKEN}`, // base64-encoded
      },
      body: JSON.stringify(body),
    });

    return await response.json();
  }

  // Handle make selection
  document.getElementById('make')?.addEventListener('input', async function () {
    const modelSelect = document.getElementById('model');
    const yearFromInput = document.getElementById('YearFrom');
    const yearToInput = document.getElementById('YearUp');

    const make = this.value;

    // Reset and disable selects
    resetAndDisableSelect(modelSelect);
    resetAndDisableSelect(yearFromInput);
    resetAndDisableSelect(yearToInput);

    if (!make) return;

    // Fetch models and years for the selected make
    const result = await fetchModelsAndYears(make);

    // Populate models
    const sortedModels = result.facets.model.choices.sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    populateSelectOptions(
      modelSelect,
      sortedModels.map((el) => {
        return {
          value: el.value,
          label: el.label,
        };
      }),
    );
    enableSelect(modelSelect);

    // Populate year ranges
    const range = result.facets.year.settings.range;
    const years = Array.from(
      { length: range.max - range.min + 1 },
      (_, i) => range.min + i,
    );
    populateSelectOptions(
      yearFromInput,
      years.map((el) => {
        return {
          value: el,
          label: el,
        };
      }),
    );
    populateSelectOptions(
      yearToInput,
      years.map((el) => {
        return {
          value: el,
          label: el,
        };
      }),
    );
    enableSelect(yearFromInput);
    enableSelect(yearToInput);

    yearToInput.tomselect.clear();
  });

  // Handle model selection
  document
    .getElementById('model')
    ?.addEventListener('input', async function () {
      const model = this.value;
      const make = document.getElementById('make').value;
      const yearFromInput = document.getElementById('YearFrom');
      const yearToInput = document.getElementById('YearUp');

      if (!(make && model)) return;

      // Reset and disable year inputs
      resetAndDisableSelect(yearFromInput);
      resetAndDisableSelect(yearToInput);

      // Fetch years for the selected make and model
      const result = await fetchModelsAndYears(make, model);

      // Populate year ranges
      const range = result.facets.year.settings.range;
      const years = Array.from(
        { length: range.max - range.min + 1 },
        (_, i) => range.min + i,
      );
      populateSelectOptions(
        yearFromInput,
        years.map((el) => {
          return {
            value: el,
            label: el,
          };
        }),
      );
      populateSelectOptions(
        yearToInput,
        years.map((el) => {
          return {
            value: el,
            label: el,
          };
        }),
      );
      enableSelect(yearFromInput);
      enableSelect(yearToInput);

      yearToInput.tomselect.clear();
    });
});
